<template>
  <div>

      <b-link
        v-for="data in posts"
        :key="data.id"
      >
        <b-card>
          <b-media>
            <template #aside>
              <b-avatar
                size="32"
                :src="data.avatar"
                :text="data.avatar"
                :variant="data.type"
              />
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ data.title }}
              </span>
            </p>
            <small class="notification-text">{{ data.body }}</small>
          </b-media>
        </b-card>
      </b-link>

    
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios"
import {
  BCard,
  BCardText,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BLink,
    BFormCheckbox,
    VuePerfectScrollbar,
    axios,
  },
  data() {
    return {
      posts: [],
    };
  },
  beforeMount:function(){
    this.onload();
  },
  methods: {
    onload(){
        axios.get('https://jsonplaceholder.typicode.com/posts')
        .then(response => {
            console.log(response)
            this.posts = response.data
        });
    } 
  }
};
</script>

<style>
</style>
